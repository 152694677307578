import axios from 'axios';
import JSEncrypt from 'jsencrypt';

const { REACT_APP_API_URL, REACT_APP_PUBLIC_KEY: publicKey } = process.env;

const instance = axios.create({ baseURL: REACT_APP_API_URL });

instance.interceptors.request.use(
  (req) => {
    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => response.data,
  ({ response }) => {
    return Promise.reject(response.data.message);
  }
);

const encrypted = (password) => {
  try {
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(publicKey);
    const encryptKey = encrypt.encrypt(password);
    return encryptKey;
  } catch (err) {
    console.log('error : ', err);
    return;
  }
};

const publicApi = {
  login: (data) => {
    const postData = {
      ...data,
      password: encrypted(data.password)
    };
    return instance({
      method: 'post',
      url: '/auth/login-web-member',
      data: postData
    });
  }
};

export default publicApi;
